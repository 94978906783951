import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconDashboard, IconDeviceAnalytics, IconBrandFramer, IconTypography, IconPalette, IconShadow, IconWindmill, IconLayoutGridAdd, IconArrowsLeftRight, IconUsers} from '@tabler/icons';
import configData from '../../../../config';

// material-ui
import { makeStyles, useTheme } from '@material-ui/styles';
import {
    Avatar,
    Card,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Paper,
    Popper,
    Switch,
    Typography
} from '@material-ui/core';
import ListItemButton from '@material-ui/core/ListItemButton';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import axios from 'axios';

// project imports
import MainCard from '../../../../ui-component/cards/MainCard';
import Transitions from '../../../../ui-component/extended/Transitions';
import UpgradePlanCard from './UpgradePlanCard';
import { LOGOUT } from './../../../../store/actions';

// assets
import { IconLogout, IconSearch, IconSettings } from '@tabler/icons';
import User1 from './../../../../assets/images/users/user-round.svg';

// constant
const icons = {
    IconDashboard: IconDashboard,
    IconDeviceAnalytics,
    IconTypography: IconTypography,
    IconPalette: IconPalette,
    IconShadow: IconShadow,
    IconWindmill: IconWindmill,
    IconBrandFramer: IconBrandFramer,
    IconLayoutGridAdd: IconLayoutGridAdd,
    IconArrowsLeftRight,
    IconUsers
};

// style const
const useStyles = makeStyles((theme) => ({
    navContainer: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%'
        },
        alignItems: "center",
        justifyContent: "center"
    },
    navParent: {
        backgroundColor: theme.palette.background.backgroundDark,
        borderRadius: '10px',
        width: 800,
        height: 50,
        margin: "0px auto 0px",
        padding: 0
    },
    navMenu: {
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
        width: 200,
        lineHeight: "50px",
        float: "left",
        listStyle: "none"
    },
    navMenuHref: { 
        flex: 1,
        width: 200,
        display: "flex",
        listStyle: "none",
        textAlign: "center",
        textDecoration: "none",
        lineHeight: 50,
        flexDirection: "row",
        color: theme.palette.text.primary,
        cursor: 'pointer',
    },
    navMenuIcon: {
        margin: "15px 0px 0px 8px",
        flex: 1,
    },
    navMenuText: {
        lineHeight: "50px",
        flex: 5,
        margin: 0,
    },
    active: {
        fontWeight: "bold",
        borderRadius: 10,
        color: theme.palette.text.textColor,
        backgroundColor: theme.palette.background.backgroundButton,
    }
}));

//-----------------------|| PROFILE MENU ||-----------------------//

const MenuSection = () => {
    const classes = useStyles();
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const account = useSelector((state) => state.account);
    const dispatcher = useDispatch();

    const IcoDashboard = icons['IconDashboard'];
    const IcoArrowsLeftRight = icons['IconArrowsLeftRight'];
    const IcoTypography = icons['IconTypography'];
    const IcoUsers = icons['IconUsers'];

    const IconDashboard = <IcoDashboard stroke={1.5} size="1.3rem" className={classes.navMenuIcon} />;
    const IconArrowsLeftRight = <IcoArrowsLeftRight stroke={1.5} size="1.3rem" className={classes.navMenuIcon} />;
    const IconTypography = <IcoTypography stroke={1.5} size="1.3rem" className={classes.navMenuIcon} />;
    const IconUsers = <IcoUsers stroke={1.5} size="1.3rem" className={classes.navMenuIcon} />;

    const getMenuClass = (path) => {
        var menuClass = classes.navMenu;
        if(window.location.href.toLowerCase().indexOf(path) != -1) {
            menuClass = [classes.navMenu, classes.active].join(' ');
        }

        return menuClass;
    }

    const getMenuHrefClass = (path) => {
        var menuClass = classes.navMenuHref;
        if(window.location.href.toLowerCase().indexOf(path) != -1) {
            menuClass = [classes.navMenuHref, classes.active].join(' ');
        }

        return menuClass;
    }

    return (
        <React.Fragment>
            <div className={classes.navContainer}>
                <ul className={classes.navParent}>
                    <li className={getMenuClass("default")}><a className={getMenuHrefClass("default")} href="/dashboard/default">{IconDashboard}<p className={classes.navMenuText}>Dashboard</p></a></li>
                    <li className={getMenuClass("history")}><a className={getMenuHrefClass("history")} href="/dashboard/history">{IconArrowsLeftRight}<p className={classes.navMenuText}>Chi tiết cuộc gọi</p></a></li>
                    <li className={getMenuClass("keywords")}><a className={getMenuHrefClass("keywords")} href="/dashboard/keywords">{IconTypography}<p className={classes.navMenuText}>Quản lý từ khoá</p></a></li>
                    <li className={getMenuClass("users")}><a className={getMenuHrefClass("users")} href="/dashboard/users">{IconUsers}<p className={classes.navMenuText}>Quản lý người dùng</p></a></li>
                </ul>
            </div>
        </React.Fragment>
    );
};

export default MenuSection;
