// assets
import { IconDashboard, IconDeviceAnalytics} from '@tabler/icons';
import { IconBrandFramer, IconTypography, IconPalette, IconShadow, IconWindmill, IconLayoutGridAdd, IconArrowsLeftRight, IconUsers} from '@tabler/icons';

// constant
const icons = {
    IconDashboard: IconDashboard,
    IconDeviceAnalytics,
    IconTypography: IconTypography,
    IconPalette: IconPalette,
    IconShadow: IconShadow,
    IconWindmill: IconWindmill,
    IconBrandFramer: IconBrandFramer,
    IconLayoutGridAdd: IconLayoutGridAdd,
    IconArrowsLeftRight,
    IconUsers
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Lịch sử cuộc gọi',
            type: 'item',
            url: '/dashboard/default',
            icon: icons['IconDashboard'],
            breadcrumbs: false
        },
        {
            id: 'call_details',
            title: 'Chi tiết cuộc gọi',
            type: 'item',
            url: '/dashboard/history',
            icon: icons['IconArrowsLeftRight'],
            breadcrumbs: false
        },
        {
            id: 'keywords',
            title: 'Quản lý từ khoá',
            type: 'item',
            url: '/dashboard/keywords',
            icon: icons['IconTypography'],
            breadcrumbs: false
        },
        {
            id: 'users',
            title: 'Quản lý người dùng',
            type: 'item',
            url: '/dashboard/users',
            icon: icons['IconUsers'],
            breadcrumbs: false
        }
    ]
};
